import Trix from 'trix';

// ビデオ挿入ダイアログの表示・非表示（showがtrueなら表示、falseなら非表示、省略なら切り替え）
function toggleDialog(toolbarElement, show) {
    const $toolbar = $(toolbarElement);
    const $dialog = $toolbar.find('.trix-dialog-x-attach-video');

    if (show == null) {
        show = $dialog.hasClass('d-none');
    }

    const $input = $dialog.find('.trix-input--dialog');
    $input.prop('disabled', !show);
    $dialog.toggleClass('d-none', !show);

    if (show) {
        $input.focus();
    } else {
        $input.removeAttr('data-trix-validate');
        $input.removeClass('trix-validate');
        $input.val('');
    }
}

function showDialog(toolbarElement) {
    toggleDialog(toolbarElement, true);
}

function hideDialog(toolbarElement) {
    toggleDialog(toolbarElement, false);
}

// リンクダイアログが表示されたときにビデオ挿入ダイアログを隠す。
// ToolbarControllerのメソッドをオーバーライドしてイベント経由で隠している。
const toolbarControllerShowDialog = Trix.controllers.ToolbarController.prototype.showDialog;

Trix.controllers.ToolbarController.prototype.showDialog = function (dialogName) {
    // Trix 2でtriggerEventが外からアクセスできなくなったので、イベントを直接投げる。
    // Trix.triggerEvent('trix-toolbar-will-show-dialog', {onElement: this.element});
    const event = document.createEvent("Events");
    event.initEvent('trix-toolbar-will-show-dialog', true, true);
    this.element.dispatchEvent(event);

    return toolbarControllerShowDialog.call(this, dialogName);
};

addEventListener('trix-toolbar-will-show-dialog', event => {
    toggleDialog(event.target, false);
});

// エディタにフォーカスしたときビデオ挿入ダイアログを隠す
addEventListener('trix-focus', event => {
    hideDialog(event.target.toolbarElement);
});

// ビデオ挿入ボタンを押すとビデオ挿入ダイアログを開閉
addEventListener('trix-action-invoke', event => {
    if (event.actionName !== 'x-attach-video') {
        return;
    }
    event.target.editorController.toolbarController.hideDialog();
    toggleDialog(event.target.toolbarElement);
});

// 閉じタグを付けるとか。HTMLが壊れてたら空になる
function normalizeHtml(text) {
    return $('<div></div>').html(text).html().trim();
}

function escapeHtml(html) {
    return $('<div></div>').text(html).html();
}

// inputのバリデーション
function checkValidity(input) {
    input.setCustomValidity('');

    if (!input.checkValidity()) {
        return false;
    }

    if (!onlyEmptyIframe(normalizeHtml(input.value))) {
        input.setCustomValidity('Invalid HTML');
        return false;
    }
    return true;
}

function onlyEmptyIframe(html) {
    const validator = document.createElement('div');
    validator.innerHTML = html;

    return validator.childNodes.length === 1 &&
        validator.firstChild.nodeName === 'IFRAME' &&
        validator.firstChild.childNodes.length === 0;
}

// ビデオの挿入
function attachVideo(editorElement) {
    const $toolbar = $(editorElement.toolbarElement);
    const $input = $toolbar.find('.trix-dialog-x-attach-video .trix-input--dialog');

    if ($input.prop('willValidate') && !checkValidity($input[0])) {
        $input.attr('data-trix-validate', '');
        $input.addClass('trix-validate');
        $input.focus();
        return;
    }
    const html = normalizeHtml($input.val());

    // iframeをアタッチすると何度も要素が作り直されて何度もロードされるので、HTMLを直接表示
    const attachment = new Trix.Attachment({
        content: createContent(html),
        html: html,
        contentType: 'text/x-attach-video'
    });
    editorElement.editor.insertAttachment(attachment);
    hideDialog(editorElement.toolbarElement);
}

function createContent(html) {
    return `<div class="trix-x-attach-video"><div class="trix-x-attach-video-inner">${escapeHtml(html)}</div></div>`
}

// ビデオ挿入ダイアログの入力欄でEnter
function didKeyDownDialogInput(event) {
    // Enter
    if (event.keyCode === 13) {
        event.preventDefault();
        attachVideo(event.data.editorElement);
    }
    // Esc
    if (event.keyCode === 27) {
        event.preventDefault();
        hideDialog(event.data.editorElement.toolbarElement);
    }
}

// ビデオ挿入ダイアログのボタンクリック
function didClickDialogButton(event) {
    attachVideo(event.data.editorElement);
}

// ビデオ挿入ボタンとダイアログの挿入
const BUTTON_HTML = `
    <button type="button" class="trix-button trix-button-video trix-button-fa-icon" data-trix-action="x-attach-video" title="Attach Video" tabIndex="-1">
        <i class="fa fa-video-camera"></i>
    </button>
`.trim();

const DIALOG_HTML = `
    <div class="trix-dialog trix-dialog--link d-none trix-dialog-x-attach-video">
        <div class="trix-dialog__link-fields">
            <input type="text" class="trix-input trix-input--dialog" placeholder="&lt;iframe ..." required disabled/>
            <div class="trix-button-group">
                <input type="button" class="trix-button trix-button--dialog" value="Insert"/>
            </div>
        </div>
    </div>
`.trim();

addEventListener('trix-initialize', event => {
    const editorElement = event.target;
    const $toolbar = $(editorElement.toolbarElement);

    if ($toolbar.find('[data-trix-action="x-attach-video"]').length === 0) {
        $toolbar.find('.trix-button-group.trix-button-group--file-tools').append(BUTTON_HTML);
        $toolbar.find('[data-trix-dialogs]').append(DIALOG_HTML);
    }

    $toolbar.find('.trix-dialog-x-attach-video')
        .off('keydown.trix-video-button')
        .on('keydown.trix-video-button', '.trix-input--dialog', {editorElement}, didKeyDownDialogInput)
        .off('click.trix-video-button')
        .on('click.trix-video-button', '.trix-button--dialog', {editorElement}, didClickDialogButton);
});

// Trix 2でHashクラスに外からアクセスできなくなったので無理やり取り出す
const file = Trix.Attachment.attributesForFile({ name: null, size: null, contentType: null });
const Hash = file.constructor;

// 保存時にcontent属性を除去するので復元する
function box(object) {
    if (object.contentType === 'text/x-attach-video') {
        object.content = createContent(object.html);
    }
    if (object instanceof Hash) {
        return object;
    } else {
        return new Hash(object);
    }
}

Hash.box = function (object) {
  return box(object);
}
